<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > <router-link :to="{path:'/reservation'}">预约服务</router-link> > {{getIndex(type)}}</div>
			<img src="@/assets/images/step4.png"/>
			<div class="content">
				<div class="info">
					<img src="@/assets/images/chenggong.png"/>
					<!--  -->
					<p>提交成功！
						<!-- {{money != 0 ? '请等待平台审核' : ''}} -->
					</p> 
					<p>可在右上角【我的预约】中查看进度</p>
				</div>
				


			</div>
			
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				type: null,
				ruleForm: {
					name: '',
					cardno: '',
					phone: '',
					commentator: '1',
				},
				money: '',
				
				
				
			}
		},
		created() {
			
		},
		mounted() {
			this.type = this.$route.query.type
			this.money = this.$route.query.money
		},
		methods: {
			getIndex(index) {
				if(index == 0) return '个人预约'
				if(index == 1) return '团队预约'
				if(index == 2) return '政务接待'
			},
			
			
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		min-height: calc(100vh - 354px);
		background: #FFFFFF;
		padding-bottom: 60px;
		>img {
			margin: 0 auto;
			margin-bottom: 20px;
			margin-top: 50px;
		}
		.content{
			min-height: 453px;
			width: 950px;
			background: url('../../assets/images/kuang.png') no-repeat;
			background-size: 100% 100%;
			margin: 0 auto;
			margin-top: 30px;
			padding: 40px 60px;
			font-size: 16px;
			color: #595E63;
			display: flex;
			align-items: center;
			justify-content: center;
			.info{
				text-align: center;
				img{
					margin: 0 auto;
				}
				p{
					&:nth-of-type(1){
						font-size: 24px;
						color: #A5343C;
						margin-top: 20px;
						margin-bottom: 10px;
					}
					&:nth-of-type(2){
						font-size: 14px;
						color: #ABABAB;
					}
				}
			}
			
			
		}
	}
}
	
</style>
